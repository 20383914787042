import React from "react"
import Layout from "../components/Layout/Layout"
import Head from "../components/Layout/Head"
import FP01Hero from "../components/FrontPage/FP01Hero"
import FP02Brand from "../components/FrontPage/FP02Brand"
import FP03Work from "../components/FrontPage/FP03Work"

import ReactFullpage from "@fullpage/react-fullpage"
import Section from "../components/Layout/Section"

const IndexPage = () => {
  const anchors = ["heroanchor", "brandanchor", "workanchor"]
  return (
    <ReactFullpage
      // fullpage options
      licenseKey={"OPEN-SOURCE-GPLV3-LICENSE"}
      scrollingSpeed={1000}
      // anchors={anchors}
      fixedElements={"header, footer"}
      render={({ state, fullpageApi }) => {
        return (
          <Layout>
            <Head title="Empathy Led Design" />
            <ReactFullpage.Wrapper>
              <Section id="1">
                <FP01Hero />
              </Section>
              <Section id="2">
                <FP02Brand />
              </Section>
              <Section id="3">
                <FP03Work />
              </Section>
            </ReactFullpage.Wrapper>
          </Layout>
        )
      }}
    />
  )
  // return (
  //   <Layout>
  //     <Head title="Empathy Led Design" />
  //     <FP01Hero />
  //     <FP02Brand />
  //     <FP03Work />
  //   </Layout>
  // )
}

export default IndexPage
