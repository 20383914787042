import React from "react"

const Section = props => {
  return (
    <div className={`section`} id={`section-${props.id}`}>
      {props.children}
    </div>
  )
}

export default Section
